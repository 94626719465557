<template>
<div v-if="showOnMobile" class="mobile-header__wrapper">
    <div class="mobile-account__header-back" @click="handleBack()"> <img src="../../../static/images/icons/icon_arrow_left_white_m.png"></div>
    <div >{{$t(title)}}</div>
    <div class="mobile-account__header-back"></div>
</div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
export default {
    props: {       
        redirectTo: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default:''
        }
    },
    mounted() {
        this.init();
    },
    computed: {
        ...mapGetters([
            'currentLocale',
            "isMobile"
        ]),
    },
    data() {
        return {
            showOnMobile: false,
        }
    },
    methods: {
        init() {
            this.showOnMobile = this.isMobile;
        },
        handleBack() {
            this.speicalHandling();

            if (this.redirectTo === '') {
                this.$router.go(-1)
            } else {
                this.$router.push(`/${this.currentLocale}`); 
            }    
        },
        
        speicalHandling() {
            //remove worldcup knockout session storage data;
            sessionStorage.removeItem("worldcupKnockout");
        }

    },
}
</script>

<style>
.mobile-header__wrapper{
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>