<template>
    <!-- <Header></Header> -->
    <div class="container">
        <div class="display-flex account-wrapper" >
            <div class="account-menu__wrapper">
                <div class="account-menu__user-wrapper">
                    <div class="account-menu__user-icon__wrapper">
                        <img :src="`${s3ImgUrl}/${userInfo.profilePicture}`" class="w-100 h-100">
                    </div>
                    <div class="account-menu__user-name">{{userInfo.displayName}}</div>
                    <div class="account-menu__user-join-date">{{$t('MEMBER_SINCE')}}<br>{{userRegistrationDate}}</div>
                </div>
                <ul class="account-menu__list-wrapper">
                    <router-link :to="{ name: o.pathName}" v-show="o.active" class="account-menu__list-container" :class="{'selected': o.selected}" v-for="(o, index) in menuList" :key="index" @click="changeRoute(o)" >{{$t(o.name)}}</router-link>
                </ul>
            </div>
            <div class="account-content__wrapper">
                <slot></slot>
            </div>
        </div>
    </div>
    <!-- <Footer ></Footer> -->
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

import config from '@/js/config.js'
import {mapGetters,mapActions} from 'vuex'

export default {
    components: {
        Header,  
        Footer
    },

    data() {
        return {
            s3ImgUrl: config.s3ImgUrl,
            menuList: [
                {
                    name: 'MY_PROFILE',
                    selected: false,
                    pathName: 'myProfile',
                    active: true,
                    id: 0
                },
                {
                    name: 'PASSWORD',
                    selected: false,
                    pathName: 'changePassword',
                    active: true,
                    id: 1
                },
                {
                    name: 'MY_PHONE_NUMBER',
                    selected: false,
                    pathName: 'myPhoneNumber',
                    active: true,
                    id: 2
                },
                {
                    name: 'DELETE_ACCOUNT',
                    selected: false,
                    pathName: 'deleteAccount',
                    active: true,
                    id: 4
                },
                {
                    name: 'MY_REFERRAL_CODE',
                    selected: false,
                    pathName: 'myReferralCode',
                    active: true,
                    id: 3
                }
            ]
        }
    },
    computed: {
        ...mapGetters([
            "userInfo",
            "userRegistrationDate",
            "currentLocale"
        ]),
    },
    mounted() {
        this.init();
    },
    methods: {
          ...mapActions([
            "getRetrieveUserInfo",
            "setUserData",
            "commonVerifyLogin"
        ]),
        init(){   
            this.commonVerifyLogin(); 
            
            let routeName = this.$route.name;

            this.menuList.forEach(x=>{              
                 if (x.pathName === routeName) {
                     x.selected = true;
                 } else {
                    x.selected = false;
                 }
            })   

            this.getRetrieveUserInfoData(); 
            this.hideChangePassword();
        },
            // get user info
        async getRetrieveUserInfoData() {
            let params = {
                token: this.userInfo.token,
                timeZone:this.$tools.getCurrentTimeZone()
            }
        
            const result = await this.getRetrieveUserInfo(params);
      
            this.setUserData(result.result.data);
        },
        changeRoute(o){
            this.menuList.forEach(x=>{              
                 if (x.pathName === o.pathName) {
                     x.selected = true;
                 } else {
                    x.selected = false;
                 }
            }) 
        },
         hideChangePassword() {
             if (this.userInfo.authProvider !== "LOCAL") {
                   this.menuList.forEach(x => {
                      if (x.id === 1) {
                        x.active = false
                      }
                 });
             }
         }
    },
}
</script>

<style>

.account-wrapper{
    margin-bottom: 16.375rem;
    align-items: flex-start;
}
.account-menu__wrapper{
    width: 17.93rem;
    margin-right: 1rem;
}
.account-menu__user-wrapper{
    background-color: var(--color-theme);
    border-radius: 1rem;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: .5rem;
    
}

.account-menu__user-icon__wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.6875rem;
    height: 3.6875rem;
    border-radius: 50%;
    border: .35rem solid var(--color-grey-40);
    margin-bottom: 0.65625rem;
    overflow: hidden;
}
.account-menu__user-name{
    font-size: 1.125rem;
    font-weight: 700;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}
.account-menu__user-join-date{
    color: #6F8C9B;
    text-align: center;
    font-size: 0.688rem;
    line-height: 1rem;
}

.account-menu__list-container{
    background-color: var(--color-theme);
    padding: 0.59375rem 1rem;
    margin-bottom: .5rem;
    border-radius: .5rem;
    font-size: 0.875rem;
    color: var(--color-grey-light);
    display: block;
}

.account-menu__list-container.selected{
    border-left: .5rem solid var(--bg-selected);
}

.account-content__wrapper{
     background-color: var(--color-theme);
     flex: 1;
     border-radius: 1rem;
     padding: 1rem;
}
</style>